/* eslint-disable */

const baseURL = 'https://restaurant.ninjastudio.dev/';

import React, { useState } from "react";
import { NavLink, Link as Anchor } from 'react-router-dom';
import './Navbar.css';
import logo from '../../images/logoChako.png';
import logo2 from '../../images/logo-2.png';


const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
    <header className="h-20">
      <div className='h-20 bg-chako-700 py-3 colo top-0 left-0 right-0 flex  items-center justify-between pr-6 z-50'>
        <div className='absolute left-1/2 transform -translate-x-1/2 mt-5'>
            <NavLink to="/">
                <img src={logo} alt="logoChako" className="contain" />
            </NavLink> 
        </div>
        <div className="ml-auto">
          <button onClick={() => setOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10 text-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div className={` ${open ? 'translate-y-0' : '-translate-y-full'} p-0 colo top-0 left-0 right-0 h-screen bg-white transition-transform duration-300  z-50`}>
          <div className="flex items-center">
            <div className="w-40 min-h-screen bg-chako-700 flex flex-row items-center">
              <button className="absolute top-4 left-12" onClick={() => setOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </button>
              <NavLink to="/">
                <img src={logo2} alt="" />
              </NavLink>
            </div>
            <div className="text-3xl sm:text-5xl w-full flex flex-col font-Montserrat font-black text-chako-700 text-left">
              <NavLink to="/reservations" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">RESERVAR</NavLink>
              <NavLink to="/productos" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">PIDE YA</NavLink>
              <NavLink to="/menu" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">CARTA</NavLink>
              <NavLink to="/" className="pl-6 p-2 hover:bg-chako-700 hover:text-white">RESTAURANTES</NavLink>
            </div>
          </div>
        </div>
      </div>
      </header>
    </>
  );
};

export default Navbar;





/*
import React, { useEffect, useState } from 'react';
import { Link as Anchor, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import logo from '../../images/logo.png'; 
import 'swiper/swiper-bundle.css';
import Profile from '../Profile/Profile';
import './Navbar.css';
import InfoUserLoguedNav from '../InfoUserLoguedNav/InfoUserLoguedNav';
import Favoritos from '../Favoritos/Favoritos';
import InputSerach from '../InputSerach/InputSearchs';
import Logout from '../Admin/Logout/Logout';
const  Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();  // Obtén la ubicación actual
    const [usuario, setUsuario] = useState({});

    useEffect(() => {
        cargarBanners();
    }, []);

    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const bannerImages = data.banner.map(banner => banner.imagen);
                setImages(bannerImages);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error);
            });
    };

    useEffect(() => {
        fetch(`${baseURL}/userLogued.php`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsuario(data);
                setLoading(false);
                console.log(data);
            })
            .catch(error => {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            });
    }, []);

    return (
        <header>
            <nav>
                <Anchor to={`/`} className='logo'>
                    <img src={logo} alt="logo" />
                    {loading ? (
                        <div></div>
                    ) : usuario.idUsuario ? (
                        <InfoUserLoguedNav />
                    ) : (
                        <></>
                    )}
                </Anchor>

                <div className='deFLexNavs'>
                    {loading ? (
                        <div></div>
                    ) : usuario.idUsuario ? (
                        <>
                        </>
                    ) : (
                        <>
                            {location.pathname !== '/meseros' && (
                                <>
                                    <Favoritos />
                                    <InputSerach />
                                </>
                            )}
                        </>
                    )}

                    <div className={`nav_toggle  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        {loading ? (
                            <div className='loadingBannerFondo'>
                            </div>
                        ) : (
                            <>
                                <div className='fondo'>
                                    <img src={images[0]} alt={`imagen`} />
                                </div>
                                <Profile />
                                {loading ? (
                                    <div></div>
                                ) : usuario.idUsuario ? (
                                    <Logout />
                                ) : (
                                    <></>
                                )}

                            </>
                        )}
                    </div>

                </Modal>
            </nav>
        </header>
    );
}

export default Navbar
*/